import React, { useEffect, useState } from "react";
import { isValidIBAN } from "ibantools";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/Layout";
import FullWidthImage from "../../components/FullWidthImage";

const AfsluitenPageTemplate = ({ pakket, modal = false, closeTo = null }) => {
  const [member, setMember] = useState("0");

  // Hook to control overflow of html tag
  useEffect(() => {
    if (modal) document.documentElement.classList.add("is-clipped");
    else document.documentElement.classList.remove("is-clipped");
    return () => {
      document.documentElement.classList.remove("is-clipped");
    };
  }, [modal]);

  // Form handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const onSubmit = async (data) => {
    console.log(data);
    data.form_name = "Pakket afsluiten";
    fetch("https://api.formbucket.com/f/buk_HJRemTmvqtIxHUdSUnjTP0ri", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        accept: "application/javascript",
        "content-type": "application/json",
      },
    })
      .then((response) => {
        reset();
        navigate("/contact/thanks/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <div className="content">
        <div className="columns is-multiline">
          <div className="column is-12">
            <div class="field">
              <label class="label">Bent u momenteel lid bij Skadi?</label>
              <div class="control">
                <div class="select">
                  <select
                    onChange={(e) => setMember(e.target.value)}
                    value={member}
                  >
                    <option value="0"></option>
                    <option value="1">
                      Ja, ik ben momenteel lid bij Skadi
                    </option>
                    <option value="2">Nee, ik ben een oud-lid van Skadi</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          {
            1: (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="columns is-multiline">
                  <div className="column is-12">
                    <p className="info-text">
                      Totale investering van €{pakket.price} verdeeld over{" "}
                      {pakket.periods} maanden (€{pakket.periodprice} per
                      maand).
                    </p>
                    <p className="info-text">
                      Ja, ik spaar mee voor een onvergetelijk Lustrum middels
                      dit spaarpakket.
                    </p>
                    <div className="field">
                      <label className="label">Gekozen Pakket</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          {...register("pakket", {
                            value: pakket.title,
                            required: true,
                          })}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-5">
                    <div className="field">
                      <label className="label">Voornaam</label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.voornaam ? "is-danger" : ""
                          }`}
                          type="text"
                          {...register("voornaam", { required: true })}
                        />
                      </div>
                      {errors.voornaam && (
                        <p className="help is-danger">Dit veld is verplicht</p>
                      )}
                    </div>
                  </div>
                  <div className="column is-2">
                    <div className="field">
                      <label className="label">Tussenvoegsel</label>
                      <div className="control">
                        <input
                          className={`input`}
                          type="text"
                          {...register("tussenvoegsel")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-5">
                    <div className="field">
                      <label className="label">Achternaam</label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.achternaam ? "is-danger" : ""
                          }`}
                          type="text"
                          {...register("achternaam", { required: true })}
                        />
                      </div>
                      {errors.achternaam && (
                        <p className="help is-danger">Dit veld is verplicht</p>
                      )}
                    </div>
                  </div>
                  <div className="column is-12">
                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control">
                        <input
                          className={`input ${errors.email ? "is-danger" : ""}`}
                          type="email"
                          {...register("email", {
                            pattern: {
                              value:
                                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                              message: "ongeldig e-mailadres",
                            },
                          })}
                          placeholder="info@skadilustrum.nl"
                        />
                      </div>
                      {errors.email && (
                        <p className="help is-danger">
                          {errors?.email?.message}
                        </p>
                      )}
                    </div>
                    <div className="field">
                      <label className="label">IBAN</label>
                      <div className="control">
                        <input
                          className={`input ${errors.iban ? "is-danger" : ""}`}
                          type="text"
                          {...register("iban", {
                            validate: (value) =>
                              isValidIBAN(value) || "ongeldige iban",
                          })}
                        />
                      </div>
                      {errors.iban && (
                        <p className="help is-danger">
                          {errors?.iban?.message}
                        </p>
                      )}
                    </div>
                    <div className="field">
                      <div className="control">
                        <label className="label">
                          Wilt u liever het bovenstaande bedrag meteen voldoen?
                        </label>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            {...register("betalen_enkel_termijn", {
                              required: false,
                            })}
                          />
                          Ik betaal het bovenstaand gekozen bedrag (€
                          {pakket.price}
                          ,-) in één keer, af te schrijven op 25 oktober 2022.
                        </label>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <label className="label">
                          Wijze van Betaling en Akkoord
                        </label>
                        <p className="info-text">
                          Hierbij geef ik toestemming aan de Algemene
                          Rotterdamse Studenten Roeivereniging “Skadi” om het
                          aangegeven bedrag (€
                          {pakket.price},-) in het hierboven aantal
                          gespecificeerde termijnen ({pakket.periods} of
                          eenmalig indien van toepassing) maandelijks op de
                          25ste van de maand van mijn rekening af te schrijven,
                          ingaande in oktober 2022. Wanneer de laatste termijn
                          zal worden afgeschreven is afhankelijk van het door u
                          gekozen pakket. Bij het incasseren wordt gebruik
                          gemaakt van de door u aan de A.R.S.R. “Skadi”
                          afgegeven incasso-machting.
                        </p>
                        <p className="info-text">
                          Ik ben me ervan bewust dat de organisatie van het
                          XIXste Lustrum zich eventuele wijzigingen voorbehoudt
                          t.a.v. de gepresenteerde spaarplannen. In het geval
                          dat één of meerdere evenementen door overmacht
                          geannuleerd worden kan geen aanspraak gemaakt worden
                          op teruggave van (een deel van) het gespaarde bedrag.
                          Bij dit contract, contractbreuk en stornering, zijn de
                          statuten en het huishoudelijk reglement van de
                          A.R.S.R. “Skadi” van toepassing. Vanaf het tekenen
                          heeft u 14 dagen tot heroverweging, hierna is de
                          overeenkomst onherroepelijk.
                        </p>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            {...register("akkoord", { required: true })}
                          />
                          Ik ben akkoord.
                        </label>
                        {errors.akkoord && (
                          <p className="help is-danger">
                            Dit veld is verplicht
                          </p>
                        )}
                      </div>
                    </div>

                    <input
                      className="input"
                      type="text"
                      {...register("price", { value: pakket.price })}
                      style={{ display: "none" }}
                      readOnly
                    />
                    <input
                      className="input"
                      type="text"
                      {...register("periods", {
                        value: pakket.periods,
                      })}
                      style={{ display: "none" }}
                      readOnly
                    />
                    <input
                      type="text"
                      {...register("__bucket_trap__")}
                      style={{ display: "none" }}
                    />

                    <div className="field is-grouped">
                      <div className="control">
                        <button
                          type="submit"
                          className="button is-primary is-link"
                        >
                          Bevestigen
                        </button>
                      </div>
                      {closeTo && (
                        <div className="control">
                          <Link
                            to={closeTo}
                            className="button is-link is-primary is-light"
                          >
                            Annuleer
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            ),
            2: (
              <div className="columns is-multiline">
                <div className="column is-12">
                  <p className="info-text">
                    Voor oud-leden worden speciale pakketten aangeboden.{" "}
                    <Link to="/pakketten/oudlid">Klik hier</Link> voor meer
                    informatie. Mocht u toch zó enthousiast zijn geworden van de
                    pakketten voor leden dat ie deze alsnog af wilt sluiten? Dan
                    kunt u een mail sturen naar{" "}
                    <a href="mailto:secretaris@skadilustrum.nl">
                      penningmeester@skadilustrum.nl
                    </a>{" "}
                    om de mogelijkheden te bespreken.
                  </p>
                </div>
              </div>
            ),
            0: (
              <div className="columns is-multiline">
                <div className="column is-12">
                  <p className="info-text">Maak een keuze</p>
                </div>
              </div>
            ),
          }[member]
        }
      </div>
    </div>
  );
};

const PakketAfsluitenPage = ({ location }) => {
  const pakket = location.state?.pakket
    ? location.state.pakket
    : { title: "Gers", price: 600, periods: 12, periodprice: 50 };
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) =>
        modal ? (
          <>
            <header className="modal-card-head">
              <h2 className="modal-card-title">U gaat voor: {pakket.title}</h2>
              {/* <Link to={closeTo}>
                <button className="delete" aria-label="close"></button>
              </Link> */}
              <Link to={closeTo}>
                <FontAwesomeIcon icon={faXmark} size="2x" />
              </Link>
            </header>
            <section className="modal-card-body pakket-form">
              <AfsluitenPageTemplate
                pakket={pakket}
                modal={modal}
                closeTo={closeTo}
              />
            </section>
          </>
        ) : (
          <Layout>
            <FullWidthImage
              title={"afsluiten"}
              subheading={pakket.title + " pakket"}
              height={"80vh"}
              color="#E8E100"
              darken={true}
            />
            <section
              className="section pakket-form no-modal"
              style={{ background: "#00412D" }}
            >
              <div className="promote-button">verkoop pakketten gesloten!</div>
            </section>
          </Layout>
        )
      }
    </ModalRoutingContext.Consumer>
  );
};

export default PakketAfsluitenPage;
